<template>
  <div class="main-container position-relative py-0 py-md-5">
    <div class="row py-5 justify-content-center">
      <div class="col-12 col-sm-10 col-md-9 ">
        <div class="row justify-content-center">
          <div class="col-5 col-sm-3 col-md-2 p-0 text-center mb-3">
            <img
              :src="require('@/assets/images/leprocess_logo_dark.png')"
              class="w-100"
              alt="Le Process logo dark"
            />
          </div>

          <div class="w-100"></div>

          <div class="col-11 col-md-9 col-lg-7 p-5">
            <p class="text-dark text-center font-size-1 font-bold line-height-sm mb-5">Félicitations !</p>

            <p class="text-light font-size-4 line-height-sm">
              Bienvenue dans Le Process, vous avez fait le bon choix.
            </p>
            <p class="text-light font-size-4 line-height-sm mb-5">
              Grâce aux heures de contenu qui vous attendent, vous saurez tout
              ce qu'un développeur débutant ou en devenir doit connaître pour
              s'assurer un beau parcours et ne pas commettre d'erreurs.
            </p>

            <p class="text-pink font-bold font-size-4 line-height-sm mb-2">
              D'ici quelques secondes, vos identifiants vous seront donnés par
              email. Pensez à regarder dans vos spams !
            </p>
            <p class="text-pink font-size-4 line-height-sm mb-3">
              Vous pourrez alors vous connecter via le bouton "Se connecter"
              dans le menu flottant.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return this.$common.getPageMeta();
  },
  jsonld() {
    return this.$common.getJsonLd();
  },
  data() {
    return {};
  },
  methods: {},
  created: function () {
    // if (!this.$common.checkFrontPaymentToken(this.$route.params.token))
    //   this.$router.push("/");
  },
  mounted: function () {},
  destroyed() {},
};
</script>
